<template>
    <el-main>
        <div class="searchdiv">
            <div class="searchform">
                <el-input v-model="searchinfo.short_url" :placeholder="$t('shortLink.short_url')"
                          :label="$t('user.account')" clearable></el-input>
                <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy"
                           :loading="isbusy">{{$t('global.search')}}
                </el-button>
            </div>
            <div>
                <el-button type="primary" @click="addShortUrl('userform')" icon="el-icon-plus">
                    {{ $t('shortLink.add_link') }}
                </el-button>
            </div>
        </div>
        <div class="pagecontent">
            <el-table ref="multipleTable"
                      :data="list"
                      tooltip-effect="dark"
                      style="width: 100%">
                <el-table-column
                        :label="$t('shortLink.short_url')"
                        width="200" prop="short_url">
                </el-table-column>
                <el-table-column
                        prop="type"
                        :label="$t('shortLink.type')"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="remarks"
                        :label="$t('global.remarks')"
                        width="250">
                </el-table-column>
                <!--                <el-table-column-->
                <!--                        :label="$t('global.state')"-->
                <!--                        width="120" prop="status">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-link :type="scope.row.status?'success':'danger'"-->
                <!--                                 @click="setenable(scope.row)">{{ scope.row.status?$t('global.enable'):$t('global.disable') }}</el-link>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column
                        prop="created_at"
                        :label="$t('global.created_at')"
                        width="135">
                </el-table-column>
                <el-table-column
                        prop="updated_at"
                        :label="$t('global.updated_at')"
                        width="135">
                </el-table-column>
                <el-table-column :label="$t('global.action')" fixed="right">
                    <template slot-scope="scope">
                        <el-link class="optlink" @click="edit(scope.row)">{{$t('global.edit')}}</el-link>
                        <el-link class="optlink" :data-clipboard-text="scope.row.short_url" @click="thisCopy()">
                            {{$t('sharelink.copy')}}
                        </el-link>
                        <el-link class="optlink" @click="deluser(scope.row)">{{$t('global.delete')}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="SizeChange"
                    @current-change="getlist"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="short_url_info.id?$t('shortLink.edit_link'):$t('shortLink.add_link')"
                   :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="short_url_info" ref="userform" :show-message="false">
                <el-form-item :label="$t('shortLink.short_url')" label-width="120px" prop="short_url"
                              v-if="short_url_info.id">
                    <el-input v-model="short_url_info.short_url" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item :label="$t('shortLink.type')" label-width="120px" prop="password">
                    <el-input v-model="short_url_info.type"></el-input>
                </el-form-item>

                <el-form-item :label="$t('shortLink.add_domain')" label-width="120px">
                    <div v-for="(domainVal, ridx) in short_url_info.domain_urls" :key="ridx" class="getrule">
                        <el-input v-model="domainVal.domain_url" class="iw-xlg" size="small">
                        </el-input>
                        <el-button @click="addDomainUrl()" size="small">增加</el-button>
                        <el-button @click="delDomainUrl(ridx)" v-if="short_url_info.domain_urls.length>1" size="small">
                            删除
                        </el-button>
                    </div>

                </el-form-item>

                <!--                <el-form-item :label="$t('global.state')" label-width="120px" required>-->
                <!--                    <el-switch v-model="short_url_info.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>-->
                <!--                </el-form-item>-->
                <el-form-item :label="$t('global.remarks')" label-width="120px" prop="remarks">
                    <el-input type="textarea" v-model="short_url_info.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}
                </el-button>
                <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
                    {{$t('global.submit')}}
                </el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src
    import Clipboard from "clipboard";

    export default {
        name: 'user',
        components: {},
        data() {
            return {
                searchinfo: {
                    page: 1,
                    pagesize: this.PAGE.pageSize,
                    short_url: '',
                },
                list: [],
                multipleSelection: [],
                totalRows: 0,
                default_short_url_info: {
                    id: 0,
                    short_url: '',
                    domain_urls: [{
                        'id': '',
                        'domain_url': '',
                    }],
                    type: '',
                    status: 1,
                    remarks: '',
                },
                short_url_info: {},
                rules: {
                    password: [
                        {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                    ]
                },
                isshowdialog: false,
                isbusy: false,
            }
        },
        methods: {
            SizeChange(val) {
                this.searchinfo.pagesize = val;
                this.getlist(1);
            },
            async dosearch() {
                this.getlist(1);
            },
            async getlist(page) {
                this.searchinfo.page = page || this.searchinfo.page;
                this.isbusy = true;
                let res = await this.httpget('/system/shortLink/index', this.searchinfo);
                if (res.code === 0) {
                    res.data.data.forEach(function (item) {
                        if (!item.domain_urls.length) {
                            item.domain_urls = [{
                                'id': '',
                                'domain_url': '',
                            }];
                        }
                    });
                    this.list = res.data.data;
                    console.log(this.list);
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                }

            },
            edit(item) {
                this.short_url_info = null;
                item.password = undefined;
                this.short_url_info = this.cloneData(item);
                this.isshowdialog = true;
            },
            addShortUrl(formName) {
                this.short_url_info = this.cloneData(this.default_short_url_info);
                this.isshowdialog = true;
                this.$nextTick(() => {
                    this.$refs[formName].resetFields();
                });
            },
            dosubmit(formName) {
                // if(!this.userformdata.sign_img || this.userformdata.sign_img==''){
                //     return this.$message({type: 'error', message: 'Please enter the signature'});
                // }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit() {
                this.isbusy = true;
                let res = await this.httppost('/system/shortLink/save', this.short_url_info);
                if (res.code === 0) {
                    this.$message({type: 'success', message: res.msg});
                    this.getlist(1);
                    this.short_url_info = {};
                    this.isshowdialog = false;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async setenable(item) {
                this.$confirm(this.$t('global.areyousure')).then(async () => {
                    this.isbusy = true;
                    let res = await this.httpput('/system/user/setenable', {uid: item.uid});
                    if (res.code === 0) {
                        this.$message({type: 'success', message: res.msg});
                        let idx = this.list.findIndex((value) => {
                            return value.uid === item.uid
                        })
                        if (idx !== -1) {
                            this.list[idx].status = Math.abs(this.list[idx].status - 1);
                        }
                        this.isbusy = false;
                    } else {
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async deluser(item) {
                this.$confirm(this.$t('global.areyousure')).then(async () => {
                    this.isbusy = true;
                    let res = await this.httpput('/system/shortLink/delete', {id: item.id});
                    if (res.code === 0) {
                        this.$message({type: 'success', message: res.msg});
                        this.getlist();
                    } else {
                        this.$message({type: 'error', message: res.msg});
                    }
                    this.isbusy = false;
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            addDomainUrl() {
                let data = {
                    id: '',
                    domain_url: ''
                }
                this.short_url_info.domain_urls.push(data);
            },
            delDomainUrl(idx) {
                if (this.short_url_info.domain_urls.length > 1) {
                    this.short_url_info.domain_urls.splice(idx, 1);
                }
            },
            thisCopy() {
                var _this = this;
                var clipboard = new Clipboard(".optlink");
                clipboard.on("success", e => {
                    this.$message({
                        message: 'Copy Success',
                        type: 'success'
                    });
                    clipboard.destroy();
                });
                clipboard.on("error", e => {
                    this.$message({
                        message: 'Does not support automatic copy',
                        type: 'warning'
                    });
                    clipboard.destroy();
                });
            },
        },
        mounted() {
            this.getlist(1);
        },
        computed: {},
    }
</script>

<style lang="scss">
    .uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }

    .preview {
        width: 178px;
        height: 50px;
        display: block;
    }

    .getrule {
        .el-button {
            margin-left: 10px;
        }
    }
</style>
